<template>
  <div class="overview">
    <div class="field-of-action"
      v-for="(toa, index) in types_of_action"
      :key="`toa-${index}`"
      :style="`color: ${toa.meta.color};`"
    >
      <h2
        :style="`border-bottom: 3px solid ${toa.meta.color}`"
      >{{ toa.output.title }}</h2>
      <div class="sections">
        <div class="section"
          v-for="(section, index) in getSections(toa.id)"
          :key="`section-${index}`"
          :style="`border-color: ${toa.meta.color}`"
          :class="getSectionStatusClass(section.output.description)"
        >
          <template v-if="section.output.description">
            <div 
              @click="openDetails(section.id, toa.meta.color)"
              class="clickable"
              v-html="section.output.title" />
          </template>
          <template v-else>
            <div v-html="section.output.title" />
          </template>
          <div class="section-id">{{ section.id }}</div>     
        </div>
      </div>
    </div>
  </div>
  <div v-if="currentDetailSection" class="details">
    <div class="controls" @click="clearDetails()">
      <img class="close" src="../assets/clear-24px.svg" alt="cancel detail view">
      <a :href="`https://cms.bua-dns.de/bolt/editcontent/sections/${currentDetailSection.id}`" target="_blank">
        <img class="edit" src="../assets/create-24px.svg" alt="cancel detail view">
      </a>
    </div>
    <div 
      class="details-header" v-html="currentDetailSection.output.title" 
      :style="`color: ${currentDetailSection.color}`"  
    />
    <div class="content">
      <div class="description">
        <h3>Definition des Handlungssektors</h3>
        <div class="description-content" v-html="currentDetailSection.output.description" />
      </div>
      <div 
        class="diagrams"
        v-if="currentDetailSection.diagrams"  
      >
        <div class="diagram"
          v-for="(diagram, i) in currentDetailSection.diagrams"
          :key="`diagram-${i}`"
        >
          <h3>{{ diagram.caption }}</h3>
          <img 
            :src="diagram.url"
            :style="`max-width: ${diagram.max_width}`"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useDnsApiData } from '../use/dns-api-data'

export default {
  name: 'overview',
  components: {

  },
  props: {
    
  },
  async setup() {
      // page config
      const pageTitel = 'Übersicht Aktionstypen'

      const store = useStore()
      const dnsApiData = await useDnsApiData()
      const fields_of_action = dnsApiData['fields_of_action']
      const types_of_action = dnsApiData['types_of_action']
      const sections = dnsApiData['sections']

      const currentDetailSection = ref( undefined )

      function openDetails(id, color) {
        const current = sections.find(s => s.id === id)
        current.color = color
        currentDetailSection.value = current
      }
      function openBoltItem(id) {
        window.open(`https://cms.bua-dns.de/bolt/editcontent/sections/${id}`)
      }
      function clearDetails() {
        currentDetailSection.value = undefined
      }

      function getSections(id) {
        const selection = sections.filter(s => {
          if (s.meta.type_of_action) {

              return s.meta.type_of_action.includes(id)
            }
          })
          return selection
      }
      function getSectionStatusClass(description) {
        return description ? 'active' : 'inactive'
      }
      const setHeaderTitle = store.commit('setHeaderTitle', pageTitel)
      
      return {
        setHeaderTitle,
        // DNS
        fields_of_action,
        types_of_action,
        sections,
        getSections,
        getSectionStatusClass,
        currentDetailSection,
        openDetails,
        clearDetails,
        openBoltItem
      }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.clickable {
  cursor: pointer;
}

.overview {
  background-color: #fff;
  margin: 0 1rem;
  text-align: center;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(220px, 1fr) );
  gap: 1rem;
  .field-of-action {
    h2 {
      min-height: 4rem;
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.3;
      padding-bottom: 0.6rem;
      margin-bottom: 0.8rem;
    }
  }
  .sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .4rem;
    .section {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        padding: .25rem;
        font-size: .8rem;
        font-weight: bold;
        word-wrap: break-word;
        border-width: 2px;
        border-style: solid;
        border-radius: 3px;
        transition: font-size 5s ease-in-out;
        &.inactive {
            border-width: 1px;    
        }
        &.active :hover {
            font-size: 103%;
        }
        .section-id {
            position: absolute;
            top: .25rem;
            right: .25rem;
            opacity: .3;
            font-size: .6rem;
        }
    }
  }
}
.details {
  margin: 2rem 1rem;
  position: relative;
  .controls {
    position: absolute;
    top: 1rem;
    left: 1rem;
    a {
      text-decoration: none;
    }
    img {
      display: inline-block;
      padding: .2rem;
      width: 1.4rem;
      border: 1px solid #ccc;
      border-radius: 2px;
      cursor: pointer;
      &.edit {
        margin-left: .4rem;        
      }
    }
  }
  .details-header {
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 1rem;
  }
  .content {
    display: grid;
    grid-template-columns: 26rem auto;
    gap: 2rem;
    font-size: .85rem;
    padding: 1rem;
    
    h3 {
      margin: 1rem 0;
      font-weight: bold;
      font-size: 1rem;
    }
    .description {
      padding-right: 1.5rem;
      border-right: 1px solid #ccc;
    }
    .diagram {
      h3 {
        font-weight: normal;
      }
      img {
        display: block;
      }
    }
  }
}
.description-content {
  line-height: 1.2;
  p {
    margin-bottom: 1rem;
  }
  h3 {
    margin: 1rem 0 1.4rem;
    font-size: 1.3rem;
  }
  h4 {
    margin: 1rem 0 1rem;
    font-size: 1.1rem;;
  }
  ul {
    list-style-type: disc;
    margin-left: 1rem;
  }
}
</style>
